import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import DocsPage from "./docs/DocsPage";

import ProfilePage from "../account/ProfilePage";
import ArchivesPage from "../snapshots/Archives";
import SnapshotListItemPage from "../snapshotListItem/SnapshotListItem";
import GroupsPage from "../snapshotGroups/Groups";
import SingleGroupsPage from "../snapshotGroups/SingleGroups";
import UsersPage from "../users/Users";
import UsersAddPage from "../users/AddUser";
import ScriptTag from 'react-script-tag';

import { LayoutSplashScreen } from "../../../_metronic";


export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <>
      <ScriptTag type="text/javascript" src="//js.hs-scripts.com/7356453.js" />

      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>


          <Route path="/users/add" component={UsersAddPage} />
          <Route path="/users/page/:page?" component={UsersPage} />
          <Route path="/users/:users_id/snapshot_groups/:groups_id/snapshots/page/:page?" component={SingleGroupsPage} />
          <Route path="/users/:users_id/snapshot_groups/:groups_id/snapshots" component={SingleGroupsPage} />
          <Route path="/users/:users_id/snapshot_groups/page/:page?" component={GroupsPage} />
          <Route path="/users/:users_id/snapshot_groups/" component={GroupsPage} />
          <Route path="/users/:id" component={UsersPage} />
          <Route path="/users" component={UsersPage} />

          <Route path="/archives/:page?" component={ArchivesPage} />

          <Route path="/snapshot_list_items/:page?" component={SnapshotListItemPage} />

          <Route path="/snapshot_groups/page/:page?" component={GroupsPage} />
          <Route path="/snapshot_groups/" component={GroupsPage} />

          <Route path="/account" component={ProfilePage} />
          <Route path="/builder" component={Builder} />
          <Route path="/" component={Dashboard} />


        </Switch>
      </Suspense>
    </>
  );
}
