import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {getSnapshotGroupsByID, getSnapshotGroupsSnapshotsCollection} from "../../crud/snapshot_groups.crud";
import * as snapshots from "../../store/ducks/snapshots.duck";

import SnapshotTableItem from "../../components/SnapshotListItem/SnapshotListItem";
import ToggleGroupVisibility from "../../components/Groups/ToggleGroupVisibility";
import CopyUrlButton from "../../components/Buttons/CopyUrlButton";
import ClearGroup from "../../components/Groups/ClearGroup";
import Can from "../../components/Can/Can";
import Pagination from "../../components/Pagination/Pagination";
import AddArchives from "../snapshots/AddArchives";

import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, Table} from "reactstrap";
import moment from "moment";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";

export function SingleGroups(props) {
    const {
        snapshots,
        users,
        match
    } = props;

    const [id, setId] = useState(0);

    const [page, setPage] = useState(snapshots.page);
    const [total, setTotal] = useState(snapshots.total);
    const [perPage, setPerPage] = useState(snapshots.perPage);

    const [groupInfo, setGroupInfo] = useState({
        name: ""
    });


    useEffect(() => {

        if (typeof match.params.groups_id !== "undefined") {

            const group_id = parseInt(match.params.groups_id);

            if(typeof match.params.page === "undefined") {
                setPage(1);
                props.changePage(1);
            } else if(page !== match.params.page) {
                setPage(match.params.page);
                props.changePage(match.params.page);
            }

            if(id !== group_id) {
                setId(group_id);

                getSnapshotGroupsByID('/snapshot_groups/' + group_id)
                    .then(({data}) => {
                        setGroupInfo(data);
                        _getSnapshotGroupsSnapshotsCollection(data['@id'], page);
                    })
                    .catch((error) => {
                        /*setSubmitting(false);
                        setStatus(
                            intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_LOGIN"
                            })
                        );*/
                    });
            }

            if(groupInfo) {

                //
            }
        }
    }, [match.params.groups_id, match.params.page]);

    const _getSnapshotGroupsSnapshotsCollection = (group = groupInfo['@id'], page) => {
        getSnapshotGroupsSnapshotsCollection(group, page)
            .then( ({ data }) => {

                setTotal(data['hydra:totalItems']);

                props.requestCollection(data);
            })
            .catch((error) => {
                /*setSubmitting(false);
                setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                );*/
            });
    }

    const handleChange = () => {

    }

    const handleSubmit = () => {

    }

    return (
        <>
            <div className="kt-portlet kt-portlet--this.stateheight-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title"><Link to={'/'}>Captures</Link> - {groupInfo.name}</h3>
                    </div>
                    { /* <PortletHeaderDropdown /> */}
                </div>
                <div className="kt-portlet__body ">
                    <Row form>
                        <Col md={6}>
                            <ToggleGroupVisibility group={groupInfo}/>
                            <ClearGroup
                                group={groupInfo}
                                onClear={() => _getSnapshotGroupsSnapshotsCollection}
                            />
                            <CopyUrlButton url={`${window.location.protocol}//${window.location.host}/snapshots/group/${groupInfo.id}`} />
                        </Col>
                        <Col md={6}>
                            <AddArchives group={groupInfo} callback={_getSnapshotGroupsSnapshotsCollection}/>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="kt-portlet kt-portlet--this.stateheight-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title"><FormattedMessage id="ARCHIVES.TABLE.TITLE"/></h3>
                    </div>
                    { /* <PortletHeaderDropdown /> */}
                </div>
                <div className="kt-portlet__body ">
                    <Form onSubmit={handleSubmit} inline>
                        <Row form style={{
                            marginBottom: 15
                        }}>
                            <Col md={12} className="d-flex">
                                { /*<FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{
                                    width: 150
                                }}>
                                    <Input
                                        type="select"
                                        name="action_type"
                                        onChange={handleChange}
                                        value={state.values.action_type}
                                    >
                                        <option>Action</option>
                                        <option value="move_to_group">Move to group</option>
                                    </Input>
                                </FormGroup>
                                <Button>Apply</Button> */}
                            </Col>
                        </Row>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>
                                    { /*<Input
                                        type="checkbox"
                                        name="select_all"
                                        onChange={this.handleChange}
                                        value={this.state.values.select_all}
                                    /> */}
                                </th>
                                <th>Created</th>
                                <th>Page title / Group title</th>
                                <th>Original URL/Group Description</th>
                                <th>Tags</th>
                                <Can
                                    roles={users.roles}
                                    perform="captures:list"
                                    yes={() => (
                                        <th>User</th>
                                    )}
                                />
                                <th>Archive</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {snapshots.snapshots.map((item, index) => {

                                return (<SnapshotTableItem
                                    key={index}
                                    item={item}
                                    onChange={() => _getSnapshotGroupsSnapshotsCollection }
                                />)

                            })}
                            </tbody>
                        </Table>
                    </Form>
                    <Pagination total={total} perPage={perPage} path={groupInfo.user + groupInfo.group}
                                page={page}/>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = store => ({
    snapshots: store.snapshots,
    users: store.auth.users
});

export default withRouter(injectIntl(
    connect(
        mapStateToProps,
        snapshots.actions
    )(SingleGroups)
));