/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";

import * as snapshots from "../store/ducks/snapshots.duck";
import { getSnapshotsCollection, deleteSnapshotsById } from "../crud/snapshots.crud";
import { updateSnapshotGroupsByID } from "../crud/snapshot_groups.crud";

import {Table, Spinner, Input, Col, Row, Form, Button, FormGroup} from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Pagination from "../components/Pagination/Pagination";

import Can from "../components/Can/Can";

class SnapshotsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: this.props.snapshots.page,
            total: this.props.snapshots.total,
            perPage: this.props.snapshots.perPage,
            values: {
                action_type: "",
                select_all: false,
                snapshots: ""
            },
            snapshot_checked: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    enableLoading = () => {
        //setLoading(true);
        this.setState({loading: true});
    };

    disableLoading = () => {
        this.setState({loading: false});
    };

    componentDidMount() {
        setTimeout(() => {
            this._getSnapshotsCollection();
        }, 1000);
    }

    componentDidUpdate() {
        if(this.state.page !== this.props.snapshots.page) {
            this.setState({ page: this.props.snapshots.page });

            this._getSnapshotsCollection();
        }
    }

    _getSnapshotsCollection() {
        this.enableLoading();
        getSnapshotsCollection(this.props.snapshots.page )
            .then( ({ data }) => {
                this.disableLoading();

                this.setState({ total: data['hydra:totalItems'] });

                this.props.requestCollection(data);
            })
            .catch((error) => {
                this.disableLoading();
                /*setSubmitting(false);
                setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                );*/
            });
    }

    _deleteSnapshotsById(id) {
        this.enableLoading();
        deleteSnapshotsById(id)
            .then( () => {
                this._getSnapshotsCollection();
            })
            .catch((error) => {
                this.disableLoading();
            });
    }

    _getUserLink(user) {
        if(user) {
            return user.email;
        }

        return;
    }

    _putSnapshotToGroup(id) {
        updateSnapshotGroupsByID('/snapshot_groups/1', {snapshots:[id]}).then( ({ data }) => {
            alert('Snapshot PLaced to the group');
        })
            .catch((error) => {
                this.disableLoading();
                /*setSubmitting(false);
                setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                );*/
            });
    }

    urlCopyClick(text, result) {
        alert('Url was copied!');
    }

    handleSubmit() {

    }

    handleChange(e) {
        /*switch (e.target.name) {
            case "select_all": {
                this.setState({
                    values: {
                        select_all: e.target.checked
                    }
                });
                console.log(this.state.snapshot_checked);
                break;
            }
            case "action_type": {
                console.log(e.target.value);
                console.log(123);
                break;
            }
        }*/
    }


    render() {
        const {
            snapshots,
            users
        } = this.props;

        const loadingOverlayClass = this.state.loading ? "kt-portlet__loading-overlay" : "kt-portlet__loading-overlay hidden";

        return (
            <>
                <div className="kt-portlet kt-portlet--this.stateheight-fluid">
                    <div className={loadingOverlayClass}>
                        <Spinner animation="border" variant="dark" />
                    </div>
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title"><FormattedMessage id="ARCHIVES.TABLE.TITLE" /></h3>
                        </div>
                        { /* <PortletHeaderDropdown /> */ }
                    </div>
                    <div className="kt-portlet__body ">
                        <Form onSubmit={this.handleSubmit} inline>
                            <Row form style={{
                                marginBottom: 15
                            }}>
                                <Col md={12} className="d-flex">
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{
                                        width: 150
                                    }}>
                                        <Input
                                            type="select"
                                            name="action_type"
                                            onChange={this.handleChange}
                                            value={this.state.values.action_type}
                                        >
                                            <option>Action</option>
                                            <option value="move_to_group">Move to group</option>
                                        </Input>
                                    </FormGroup>
                                    <Button>Apply</Button>
                                </Col>
                            </Row>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th>
                                        <Input
                                            type="checkbox"
                                            name="select_all"
                                            onChange={this.handleChange}
                                            value={this.state.values.select_all}
                                        />
                                    </th>
                                    <th>Created</th>
                                    <th>Page title / Group title</th>
                                    <th>Original URL/Group Description</th>
                                    <th>Tags</th>
                                    <Can
                                        roles={users.roles}
                                        perform="captures:list"
                                        yes={() => (
                                            <th>User</th>
                                        )}
                                    />
                                    <th>Archive</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                { snapshots.snapshots.map((item, index) => {

                                    /*this.setState({
                                        snapshot_checked: [
                                            ...this.snapshot_checked
                                        ]
                                    });*/

                                    const dateCreatedMoment = moment(item.timestamp);
                                    const url = new URL(item.url);

                                    let viewHTML,
                                        downloadScreenshot,
                                        downloadPDF;

                                    if(item.views.length) {
                                        viewHTML = typeof item.views[0].viewUrl === "undefined" ? "" : <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        View
                                                    </Tooltip>
                                                }
                                            ><Link
                                            to={`/snapshots/${ item.id }`}
                                            className="btn btn-clean btn-md btn-icon"
                                            target='_blank'
                                        >
                                            <i className="la la-eye" />
                                        </Link></OverlayTrigger>;

                                        downloadPDF = typeof item.views[2].downloadUrl === "undefined" ? "" : <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        Get PDF
                                                    </Tooltip>
                                                }
                                            ><a
                                            href={ item.views[2].downloadUrl }
                                            className="btn btn-clean btn-md btn-icon"
                                        >
                                            <i className="la la-file-pdf-o" />
                                        </a></OverlayTrigger>;

                                        downloadScreenshot = typeof item.views[1].downloadUrl === "undefined" ? "" : <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        Get PNG
                                                    </Tooltip>
                                                }
                                            ><a
                                            href={ item.views[1].downloadUrl }
                                            className="btn btn-clean btn-md btn-icon"
                                        >
                                            <i className="la la-file-image-o" />
                                        </a></OverlayTrigger>;
                                    }

                                    return (
                                        <tr key={ index }>
                                            <td>
                                                <Input
                                                    type="checkbox"
                                                    name="snapshots"
                                                    checked={this.state.snapshot_checked[index]}
                                                    onChange={this.handleChange.bind(this, index)}
                                                    value={item['@id']}
                                                />
                                            </td>
                                            <td>{ dateCreatedMoment.format("MM.DD.YYYY") }<br />{ dateCreatedMoment.format("HH:mm:ss") }</td>
                                            <td style={{
                                                width: "30%"
                                            }}>{ item.title }</td>
                                            <td style={{
                                                width: "10%"
                                            }}><a href={ url.href } target={`_blank`}><img src={item.favicon} /> { url.origin }</a></td>
                                            <td>
                                                <button className="btn btn-outline-dark btn-sm">Tag name</button>
                                                <button className="btn btn-outline-dark btn-sm">Tag name</button>
                                            </td>
                                            <Can
                                                roles={users.roles}
                                                perform="captures:list"
                                                yes={() => (
                                                    <td>{ this._getUserLink(item.user) }</td>
                                                )}
                                            />
                                            <td>
                                                {viewHTML}
                                                { /* <Link
                                                    to={`/snapshots/${ item.id }/zip`}
                                                    className="btn btn-clean btn-md btn-icon"
                                                >
                                                    <i className="la la-file-archive-o" />
                                                </Link> */ }
                                                {downloadPDF}
                                                {downloadScreenshot}
                                                <Can
                                                    roles={users.roles}
                                                    perform="captures:delete"
                                                    yes={() => (
                                                        <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                        <Tooltip>
                                                        Delete
                                                        </Tooltip>
                                                    }
                                                        ><button
                                                            type="button"
                                                            className="btn btn-clean btn-md btn-icon"
                                                            onClick={() => this._deleteSnapshotsById(item['@id'])}
                                                        >
                                                            <i className="la la-trash" />
                                                        </button></OverlayTrigger>
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            Copy URL
                                                        </Tooltip>
                                                    }
                                                >
                                                    <CopyToClipboard
                                                        text={`${ window.location.href }snapshots/${ item.id }`}
                                                        onCopy={(text, result) => this.urlCopyClick(text, result) }
                                                    >
                                                       <button
                                                            type="button"
                                                            className="btn btn-clean btn-md btn-icon"
                                                        >
                                                            <i className="la la-copy" />
                                                        </button>
                                                    </CopyToClipboard>
                                                </OverlayTrigger>

                                            <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Put to group
                                                </Tooltip>
                                            }
                                        ><button
                                            className="btn btn-clean btn-md btn-icon"
                                            onClick={() => this._putSnapshotToGroup(item['@id']) }
                                            type="button"
                                        >
                                            <i className="la la-folder-open" />
                                        </button></OverlayTrigger>
                                            </td>
                                        </tr>
                                    );
                                }) }
                                </tbody>
                            </Table>
                        </Form>
                        <Pagination total={this.state.total} perPage={this.state.perPage} path="/archives" page={this.state.page}/>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = store => ({
    snapshots: store.snapshots,
    users: store.auth.users
});

export default injectIntl(
    connect(
        mapStateToProps,
        snapshots.actions
    )(SnapshotsTable)
);

