import axios from "axios";

import { SNAPSHOT_GROUPS_URL  } from "../config/app.api.config";

export function getSnapshotGroupsCollection(page, perPage = 30) {
  return axios.get(SNAPSHOT_GROUPS_URL, {
    params: {
      page: page,
      perPage: perPage
    }
  });
}

export function getSnapshotGroupsByID(id) {
  return axios.get(id);
}

export function deleteSnapshotGroupsById(id) {
  return axios.delete(id);
}

export function updateSnapshotGroupsByID(id, params) {
  return axios.put(id, params);
}

export function addSnapshotGroups(name, creator) {
  return axios.post(SNAPSHOT_GROUPS_URL, { name: name, creator: creator });
}

export function getSnapshotGroupsSnapshotsCollection(group, page = 1) {
  return axios.get(group+'/snapshots', {
    params: {
      page: page
    }
  });
}