/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";

import * as snapshotGroups from "../store/ducks/snapshot_groups.duck";
import { getSnapshotGroupsCollection, deleteSnapshotGroupsById } from "../crud/snapshot_groups.crud";

import { Table, Spinner } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Pagination from "../components/Pagination/Pagination";

import Can from "../components/Can/Can";

class SnapshotGroupsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: this.props.snapshotGroups.page,
            total: this.props.snapshotGroups.total,
            perPage: this.props.snapshotGroups.perPage
        };
    }

    enableLoading = () => {
        //setLoading(true);
        this.setState({loading: true});
    };

    disableLoading = () => {
        this.setState({loading: false});
    };

    componentDidMount() {
        setTimeout(() => {
            this._getSnapshotGroupsCollection();
        }, 1000);
    }

    componentDidUpdate() {
        if(this.state.page !== this.props.snapshotGroups.page) {
            this.setState({ page: this.props.snapshotGroups.page });

            this._getSnapshotGroupsCollection();
        }
    }

    _getSnapshotGroupsCollection() {
        this.enableLoading();
        getSnapshotGroupsCollection( this.props.snapshotGroups.page )
            .then( ({ data }) => {
                this.disableLoading();

                this.setState({ total: data['hydra:totalItems'] });

                this.props.requestCollection(data);
            })
            .catch((error) => {
                this.disableLoading();
                /*setSubmitting(false);
                setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                );*/
            });
    }

    _deleteSnapshotGroupsById(id) {
        this.enableLoading();
        deleteSnapshotGroupsById(id)
            .then( () => {
                this.disableLoading();
                this._getSnapshotGroupsCollection();
            })
            .catch((error) => {
                this.disableLoading();
            });
    }

    _getUserLink(user) {
        if(user) {
            return user.email;
        }

        return;
    }

    urlCopyClick(text, result) {
        alert('Url was copied!');
    }


    render() {
        const {
            snapshotGroups,
            users
        } = this.props;

        const loadingOverlayClass = this.state.loading ? "kt-portlet__loading-overlay" : "kt-portlet__loading-overlay hidden";

        return (
            <>
                <div className="kt-portlet kt-portlet--this.stateheight-fluid">
                    <div className={loadingOverlayClass}>
                        <Spinner animation="border" variant="dark" />
                    </div>
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title"><FormattedMessage id="SNAPSHOTGROUPS.TABLE.TITLE" /></h3>
                        </div>
                        { /* <PortletHeaderDropdown /> */ }
                    </div>
                    <div className="kt-portlet__body ">
                        <Table responsive>
                            <thead>
                            <tr>
                                <th></th>
                                <th>Group title</th>
                                <th>Snapshots count</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            { snapshotGroups.snapshotGroups.map((item, index) => {
                                return (
                                    <tr key={ index }>
                                        <td style={{
                                            width: "40px"
                                        }}>
                                            <input type="checkbox"/>
                                        </td>
                                        <td >{ item.name }</td>
                                        <td style={{
                                            width: "10%"
                                        }}>{ item.snapshotsCount }</td>
                                        <td style={{
                                            width: "10%"
                                        }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        View
                                                    </Tooltip>
                                                }
                                            ><Link
                                                to={ `${item.creator['@id'] + item['@id']}/snapshots` }
                                                className="btn btn-clean btn-md btn-icon"
                                                target='_blank'
                                            >
                                                <i className="la la-eye" />
                                            </Link></OverlayTrigger>

                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        Ungroup
                                                    </Tooltip>
                                                }
                                            ><button
                                                type="button"
                                                className="btn btn-clean btn-md btn-icon"
                                            >
                                                <i className="la la-folder-open" />
                                            </button></OverlayTrigger>

                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        Delete
                                                    </Tooltip>
                                                }
                                            ><button
                                                type="button"
                                                className="btn btn-clean btn-md btn-icon"
                                                onClick={() => this._deleteSnapshotGroupsById(item['@id'])}
                                            >
                                                <i className="la la-trash" />
                                            </button></OverlayTrigger>
                                        </td>
                                    </tr>
                                );
                            }) }
                            </tbody>
                        </Table>

                        <Pagination total={snapshotGroups.total} perPage={snapshotGroups.perPage} path="/snapshot_groups/page" page={this.state.page}/>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = store => ({
    snapshotGroups: store.snapshotGroups,
    users: store.auth.users
});

export default injectIntl(
    connect(
        mapStateToProps,
        snapshotGroups.actions
    )(SnapshotGroupsTable)
);

