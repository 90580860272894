import React from "react";

import { addSnapshotGroups } from "../../crud/snapshot_groups.crud";
import * as auth from "../../store/ducks/auth.duck";
import * as snapshotGroups from "../../store/ducks/snapshot_groups.duck";
import * as snapshotListItem from "../../store/ducks/snapshotListItem.duck";

import { Col, Row, Button, Form, FormGroup, Label, Input, Alert, FormFeedback, Spinner } from "reactstrap";
import {injectIntl} from "react-intl";
import { bindActionCreators } from "redux";
import {connect} from "react-redux";

export class AddSnapshotGroups extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            snapshotGroupsName: ""
        };
    }

    enableLoading() {
        //setLoading(true);
        this.setState({loading: true});
    };

    disableLoading() {
        this.setState({loading: false});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        this.enableLoading();
        addSnapshotGroups(this.state.snapshotGroupsName, this.props.usersAuth['@id'])
            .then( ({ data }) => {
                this.disableLoading();
                alert('Group '+ this.state.snapshotGroupsName +' created');

                this.setState({ snapshotGroupsName: "" });

                this.props.add();
                this.props.refresh();
            })
            .catch((error) => {
                this.disableLoading();
            });

    }

    handleChange = (event) => {
        this.setState({snapshotGroupsName: event.target.value});
    }

    render() {
        const loadingOverlayClass = this.state.loading ? "kt-portlet__loading-overlay" : "kt-portlet__loading-overlay hidden";

        return (
            <Row>
                <Col sm={{ size: 6, offset: 6 }} className="clearfix" style={{
                    paddingBottom: 25
                }}>
                    <Form onSubmit={this.handleSubmit} noValidate autoComplete="off" inlines="true" style={{
                        "justifyContent": "flex-end",
                        "display": "flex"
                    }}>
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{
                            "width": "calc(100% - 150px)"
                        }}>
                            <Input
                                type="text"
                                name="snapshots_groups_name"
                                placeholder="Name"
                                value={this.state.snapshotGroupsName}
                                onChange={this.handleChange}
                                style={{
                                    "width": "100%"
                                }}
                            />
                        </FormGroup>
                        <Button style={
                            {
                                "width": 140
                            }
                        }>Add group</Button>
                    </Form>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = store => ({
    usersAuth: store.auth.users
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators( Object.assign({}, auth.actions, snapshotGroups.actions, snapshotListItem.actions), dispatch)
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddSnapshotGroups)
);