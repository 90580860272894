import React, {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";

import {getSnapshotGroupsByID, getSnapshotGroupsSnapshotsCollection} from "../../crud/snapshot_groups.crud";

import PublicSnapshotListItem from "../../components/SnapshotListItem/PublicSnapshotListItem";
import Pagination from "../../components/Pagination/Pagination";

import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, Table} from "reactstrap";
import moment from "moment";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import "../../../_metronic/_assets/sass/pages/group/b-group.scss";

export default function SingleGroups(props) {
    const {
        match
    } = props;

    const [id, setId] = useState(0);

    const [snapshots, setSnapshots] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [perPage, setPerPage] = useState(30);
    const [isAuthorized, setIsAuthorized] = useState(true);

    const [groupInfo, setGroupInfo] = useState({
        name: ""
    });


    useEffect(() => {

        if (typeof match.params.id !== "undefined") {

            const group_id = parseInt(match.params.id);

            if(typeof match.params.page === "undefined") {
                setPage(1);
            } else if(page !== match.params.page) {
                setPage(match.params.page);
            }

            if(id !== group_id) {
                setId(group_id);

                getSnapshotGroupsByID('/snapshot_groups/' + group_id)
                    .then(({data}) => {
                        setGroupInfo(data);
                        _getSnapshotGroupsSnapshotsCollection(data['@id'], page);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if(error.response.status === 401) {
                            setIsAuthorized(false);
                        }
                        setGroupInfo(false);
                    });
            }

            if(groupInfo) {

                //
            }
        }
    }, [match.params.id, match.params.page]);

    const _getSnapshotGroupsSnapshotsCollection = (group = groupInfo['@id'], page) => {
        getSnapshotGroupsSnapshotsCollection(group, page)
            .then( ({ data }) => {
                setSnapshots(data['hydra:member']);
                setTotal(data['hydra:totalItems']);
            })
            .catch((error) => {
                /*setSubmitting(false);
                setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                );*/
            });
    }

    const handleChange = () => {

    }

    const handleSubmit = () => {

    }

    const dateCreatedMoment = moment(groupInfo.created);

    if(!isAuthorized) {
        return(
            <Redirect to="/auth/login" />
        );
    }

    if(groupInfo === false) {
        return (
            <>
                <div className="b-group">
                    <div className="b-group__header">
                        <div className="container-fluid">
                            <div className="b-group__logo">
                                <img src={toAbsoluteUrl("/media/logos/logo-dark.svg")} alt=""/>
                            </div>
                            <div className="b-group__title">Group is private!</div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

    return (
        <>
            <div className="b-group">
                <div className="b-group__header">
                    <div className="container-fluid">
                        <div className="b-group__logo">
                            <img src={toAbsoluteUrl("/media/logos/logo-dark.svg")} alt=""/>
                        </div>
                        <div className="b-group__title">{groupInfo.name}</div>
                        <div className="b-group__created">Created: {dateCreatedMoment.format("DD.MM.YYYY")} {dateCreatedMoment.format("HH:mm:ss")}</div>
                    </div>
                </div>
                <div className="b-group__body">
                    <div className="kt-portlet kt-portlet--this.stateheight-fluid">
                        <div className="kt-portlet__body ">
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th>Created</th>
                                    <th>Page title</th>
                                    <th>Original URL</th>
                                    <th>Tags</th>
                                    <th>Archive</th>
                                    <th>Copy url</th>
                                </tr>
                                </thead>
                                <tbody>
                                {snapshots.map((item, index) => {

                                    return (<PublicSnapshotListItem
                                        key={index}
                                        item={item}
                                    />)

                                })}
                                </tbody>
                            </Table>

                            <Pagination total={total} perPage={perPage} path={`/snapshots/group/${groupInfo.id}/page`}
                                        page={page}/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}