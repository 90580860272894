/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";

import * as snapshotListItem from "../store/ducks/snapshotListItem.duck";
import {getSnapshotListItemCollection} from "../crud/snapshotListItem.crud";
import {getSnapshotGroupsCollection} from "../crud/snapshot_groups.crud";
import {updateSnapshotGroupsByID} from "../crud/snapshot_groups.crud";
import {editSnapshotByID} from "../crud/snapshots.crud";
import {Table, Spinner, Input, Col, Row, Form, Button, FormGroup} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

import Pagination from "../components/Pagination/Pagination";
import SnapshotListItem from "../components/SnapshotListItem/SnapshotListItem";
import GroupListItem from "../components/GroupListItem/GroupListItem";

import Can from "../components/Can/Can";

class SnapshotListItemTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: this.props.snapshotListItem.page,
            total: this.props.snapshotListItem.total,
            perPage: this.props.snapshotListItem.perPage,
            groups: [],
            values: {
                action_type: "",
                select_all: false,
                snapshots: [],
                action_group: "null"
            },
            snapshots_check: this.props.snapshotListItem.snapshotListItem.reduce(
                (options, option) => ({
                    ...options,
                    [option.object['@id']]: false
                }),
                {}
            )
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this._getSnapshotListItemCollection = this._getSnapshotListItemCollection.bind(this);
    }

    enableLoading = () => {
        //setLoading(true);
        this.setState({loading: true});
    };

    disableLoading = () => {
        this.setState({loading: false});
    };

    componentDidMount() {
        setTimeout(() => {
            this._getSnapshotListItemCollection();
            this._getSnapshotGroupsCollection();
        }, 1000);
    }

    componentDidUpdate() {
        if (this.state.page !== this.props.snapshotListItem.page) {
            this.setState({page: this.props.snapshotListItem.page});

            this._getSnapshotListItemCollection();
        }
    }

    _getSnapshotListItemCollection() {
        this.enableLoading();

        getSnapshotListItemCollection(this.props.snapshotListItem.page)
            .then(({data}) => {
                this.disableLoading();

                this.setState({total: data['hydra:totalItems']});

                this.props.requestCollection(data);
            })
            .catch((error) => {
                this.disableLoading();
                /*setSubmitting(false);
                setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                );*/
            });
    }

    _getSnapshotGroupsCollection() {
        getSnapshotGroupsCollection(1, 999)
            .then(({data}) => {
                this.setState({groups: data['hydra:member']});

                //this.props.requestCollection(data);
            })
            .catch((error) => {
                this.disableLoading();
                /*setSubmitting(false);
                setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                );*/
            });
    }

    /**/

    selectAll(isSelected) {
        Object.keys(this.state.snapshots_check).forEach(checkbox => {
            this.setState(prevState => ({
                snapshots_check: {...prevState.snapshots_check, [checkbox]: isSelected}
            }));
        });
    }

    handleSubmit(e) {
        e.preventDefault();



        if(this.state.values.action_group === "null") {
            alert('Please select the group');
            return;
        }


        this.state.values.snapshots.map((item) => {
            editSnapshotByID(item, {snapshotGroup: this.state.values.action_group}).then(({data}) => {

            })
                .catch((error) => {

                });
        });

        this.selectAll(false);

        setTimeout(() => {
            alert('Snapshots moved to the group.');
            this._getSnapshotListItemCollection();
        }, 500);
    }

    handleChange(e) {
        switch (e.target.name) {
            case "select_all": {

                this.selectAll(e.target.checked);

                break;
            }
            /*case "action_type": {
                console.log(e.target.value);
                console.log(123);
                break;
            }*/
            case "snapshots": {
                const options = this.state.values.snapshots
                let index
                if (e.target.checked) {
                    options.push(e.target.value)
                } else {
                    index = options.indexOf(e.target.value)
                    options.splice(index, 1)
                }

                options.sort();


                this.setState({
                    values: {...this.state.values, snapshots: options},
                    snapshots_check: {...this.state.snapshots_check, [e.target.value]: e.target.checked}
                });

                break;
            }

            case "action_group": {
                this.setState({
                    values: {...this.state.values, action_group: e.target.value}
                });
                break;
            }
        }
    }


    render() {
        const {
            snapshotListItem,
            users
        } = this.props;

        const loadingOverlayClass = this.state.loading ? "kt-portlet__loading-overlay" : "kt-portlet__loading-overlay hidden";

        return (
            <>
                <div className="kt-portlet kt-portlet--this.stateheight-fluid">
                    <div className={loadingOverlayClass}>
                        <Spinner animation="border" variant="dark"/>
                    </div>
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title"><FormattedMessage id="ARCHIVES.TABLE.TITLE"/></h3>
                        </div>
                        { /* <PortletHeaderDropdown /> */}
                    </div>
                    <div className="kt-portlet__body ">
                        <Form onSubmit={this.handleSubmit} inline>
                            <Row form style={{
                                marginBottom: 15
                            }}>
                                <Col md={12} className="d-flex">
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{
                                        width: 150
                                    }}>
                                        <Input
                                            type="select"
                                            name="action_type"
                                            onChange={this.handleChange}
                                            value={this.state.values.action_type}
                                        >
                                            { /*<option>Action</option> */}
                                            <option value="move_to_group">Move to group</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{
                                        width: 180
                                    }}>
                                        <Input
                                            type="select"
                                            name="action_group"
                                            onChange={this.handleChange}
                                            value={this.state.values.action_group}
                                            style={{
                                                width: "100%"
                                            }}
                                        >
                                            <option key="no_group" value="null">Choose the group...</option>
                                            {this.state.groups.map((item, index) => {
                                                return (<option key={index} value={item['@id']}>{item.name}</option>);
                                            })}
                                        </Input>
                                    </FormGroup>
                                    <Button>Apply</Button>
                                </Col>
                            </Row>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th>
                                        { /*<Input
                                            type="checkbox"
                                            name="select_all"
                                            onChange={this.handleChange}
                                            value={this.state.values.select_all}
                                        />*/}
                                    </th>
                                    <th>Created</th>
                                    <th>Page title / Group title</th>
                                    <th>Original URL/Group Description</th>
                                    <th>Tags</th>
                                    <Can
                                        roles={users.roles}
                                        perform="captures:list"
                                        yes={() => (
                                            <th>User</th>
                                        )}
                                    />
                                    <th>Archive</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {snapshotListItem.snapshotListItem.map((item, index) => {
                                    if (item.object['@type'] === "Snapshot") {
                                        return (<SnapshotListItem
                                            onChangeCheckbox={this.handleChange.bind(this)}
                                            onChange={ () => this._getSnapshotListItemCollection }
                                            key={index}
                                            item={item.object}
                                            isSelected={this.state.snapshots_check[item.object['@id']]}
                                        />)

                                    } else if (item.object['@type'] === "SnapshotGroup") {
                                        return (<GroupListItem key={index} item={item.object}/>)
                                    } else {
                                        //return null;
                                    }
                                })}
                                </tbody>
                            </Table>
                        </Form>
                        <Pagination total={this.state.total} perPage={this.state.perPage} path="/snapshot_list_items"
                                    page={this.state.page}/>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = store => ({
    snapshotListItem: store.snapshotListItem,
    users: store.auth.users
});

export default injectIntl(
    connect(
        mapStateToProps,
        snapshotListItem.actions
    )(SnapshotListItemTable)
);

