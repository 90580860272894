

export default {
  header: {
    self: {},
    items: [
      {
        title: "Users",
        root: true,
        alignment: "left",
        page: "users",
        translate: "MENU.USERS",
        permission: "users:get"
      }
    ]
  },
  aside: {
    self: {},
    items: [

    ]
  }
};
