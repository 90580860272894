import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Input} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import CopyUrlButton from "../../components/Buttons/CopyUrlButton";
import {deleteSnapshotGroupsById} from "../../crud/snapshot_groups.crud";
import * as snapshotListItem from "../../store/ducks/snapshotListItem.duck";
import Can from "../Can/Can";
import moment from "moment";
import ToggleGroupVisibility from "../Groups/ToggleGroupVisibility";

export function GroupListItem(props) {
    const [item, setItem] = useState(0);

    useEffect(() => {
        if (item !== props.item) {
            setItem(props.item);
        }
    });

    if (!item) {
        return null;
    }

    const {users} = props;

    const _getUserLink = (user) => {
        if (user) {
            return user.email;
        }

        return;
    }

    const _deleteSnapshotGroupsById = (id) => {
        deleteSnapshotGroupsById(id)
            .then(() => {
                props.refresh();
            })
            .catch((error) => {

            });
    }

    const urlCopyClick = (text, result) => {
        alert('Url was copied!');
    }


    const dateCreatedMoment = moment(item.created);

    return (
        <tr>
            <td>
                <i style={{
                    "color": "#93a2dd",
                    "fontSize": 16
                }} className="la la-folder"></i>
            </td>
            <td>{dateCreatedMoment.format("DD.MM.YYYY")}<br/>{dateCreatedMoment.format("HH:mm:ss")}</td>
            <td style={{
                width: "30%"
            }}><b>{item.name}</b></td>
            <td style={{
                width: "10%"
            }}></td>
            <td>

            </td>
            <Can
                roles={users.roles}
                perform="captures:list"
                yes={() => (
                    <td>{_getUserLink(item.creator)}</td>
                )}
            />
            <td>
                { /* <Link
                                                    to={`/snapshots/${ item.id }/zip`}
                                                    className="btn btn-clean btn-md btn-icon"
                                                >
                                                    <i className="la la-file-archive-o" />
                                                </Link> */}
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            View
                        </Tooltip>
                    }
                ><Link
                    to={`${item.creator['@id'] + item['@id']}/snapshots`}
                    className="btn btn-clean btn-md btn-icon"
                >
                    <i className="la la-eye"/>
                </Link></OverlayTrigger>

                <ToggleGroupVisibility group={item}/>

                <Can
                    roles={users.roles}
                    perform="captures:delete"
                    yes={() => (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Delete
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                className="btn btn-clean btn-md btn-icon"
                                onClick={() => _deleteSnapshotGroupsById(item['@id'])}
                            >
                                <i className="la la-trash"/>
                            </button>
                        </OverlayTrigger>
                    )}
                />
            </td>
            <td>
                <CopyUrlButton url={`${window.location.protocol}//${window.location.host}/snapshots/group/${item.id}`} />
            </td>
        </tr>
    );
}

const mapStateToProps = store => ({
    users: store.auth.users
});

export default injectIntl(
    connect(
        mapStateToProps,
        snapshotListItem.actions
    )(GroupListItem)
);