import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {injectIntl} from "react-intl";

import * as snapshotListItem from "../../store/ducks/snapshotListItem.duck";

import SnapshotListItemTable from "../../widgets/SnapshotListItemTable";
import AddArchives from "../snapshots/AddArchives";
import AddSnapshotGroups from "../snapshotGroups/AddSnapshotGroups";
import {Col, Row} from "reactstrap";


export function SnapshotListItems(props) {

    useEffect(() => {
        const { match, page } = props;

        if(typeof match.params.page !== "undefined" && page != match.params.page) {

            props.changePage(parseInt(match.params.page));
        }
    });

    return (
        <>
            <Row>
                <Col sm={{ size: 6, offset: 6 }} className="clearfix" style={{
                    paddingBottom: 25
                }}>
                    <AddArchives />
                </Col>
            </Row>

            <AddSnapshotGroups />

            <SnapshotListItemTable />
        </>
    );
}

const mapStateToProps = store => ({
    page: store.snapshotListItem.page
});

export default withRouter(injectIntl(
    connect(
        mapStateToProps,
        snapshotListItem.actions
    )(SnapshotListItems)
));