/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { FormattedMessage } from "react-intl";

class UserProfile extends React.Component {
  render() {
    const {
      users,
      showHi,
      showAvatar,
      showBadge
    } = this.props;

    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">

            {showHi && (
              <span className="kt-header__topbar-username kt-hidden-mobile">
                {users.email}
              </span>
            )}

            {/*showAvatar && <img alt="Pic" src={users.pic} />*/}

            {showBadge && (
              <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                {/* TODO: Should get from currentUser */}
                John Doe
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
            }}
          >
            {/*<div className="kt-user-card__avatar">
              <img alt="Pic" className="kt-hidden" src={users.pic} />
            </div>*/}
            <div className="kt-user-card__name">{users.email}</div>
          </div>
          <div className="kt-notification">
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <Link to="/account" className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  <FormattedMessage id="PROFILE.MENU.TITLE" />
                </div>
                <div className="kt-notification__item-time">
                  <FormattedMessage id="PROFILE.MENU.SUBTITLE" />
                </div>
              </Link>
            </a>
            <div className="kt-notification__custom">
              <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
              >
                <FormattedMessage id="PROFILE.MENU.LOGOUT" />
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { users } }) => ({
  users
});

export default connect(mapStateToProps)(UserProfile);
