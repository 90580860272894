import axios from "axios";
import { getUserIdFromToken } from "../utils/utils";

import { USERS_URL  } from "../config/app.api.config";

export function updateUsersById(id, email, password) {
  return axios.put(id, {email, password});
}

export function getUserById(id) {
  return axios.get(id);
}

export function getUserByToken(token) {
  const id = getUserIdFromToken(token);

  return getUserById(id);
}

export function getUsersCollection(page) {
  return axios.get(USERS_URL, {
    params: {
      page: page
    }
  });
}

export function deleteUsersById(id) {
  return axios.delete(id);
}

export function updateUser(id) {

}

export function addUsers(values, token = null) {
  if(token) {
    return axios.post(USERS_URL, values, {
      headers: {
        'Authorization': "Bearer " + token
      }
    });
  } else {
    return axios.post(USERS_URL, values);
  }
}