import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getSnapshotGroupsCollection } from "../../crud/snapshot_groups.crud";

export const actionTypes = {
  CollectionRequested: "[Load snapshot groups collection] Action",
  SnapshotsRequest: "[Load group by id] Action",
  Delete: "[Delete group] Action",
  ChangePage: "[Change group page] Action",
  Add: "[Add group] Action",
};

const initialSnapshotGroupsState = {
  snapshotGroups: [],
  total: 0,
  perPage: 30,
  page: 1
};
export const reducer = persistReducer(
    { storage, key: "snapshotGroups"},
    (state = initialSnapshotGroupsState, action) => {
      switch (action.type) {
        case actionTypes.CollectionRequested: {
          const { snapshotGroups } = action.payload;

          return { ...state, snapshotGroups:snapshotGroups['hydra:member'], total: snapshotGroups['hydra:totalItems'] };
        }

        case actionTypes.Delete: {
          return state;
        }

        case actionTypes.Add: {
          return state;
        }

        case actionTypes.ChangePage: {
          const { page } = action.payload
          return { ...state, page };
        }

        default:
          return state;
      }
    }
);

export const actions = {
  requestCollection: snapshotGroups => ({
    type: actionTypes.CollectionRequested,
    payload: { snapshotGroups }
  }),
  deleteSnapshotGroups: () => ({
    type: actionTypes.Delete
  }),
  add: () => ({
    type: actionTypes.Add
  }),
  changePage: page => ({
    type: actionTypes.ChangePage,
    payload: { page }
  })
};

export function* saga() {
  yield takeLatest(actionTypes.Add, function* addSaga() {
    const { data } = yield getSnapshotGroupsCollection();
    yield put(actions.requestCollection(data));
  });
}