import axios from "axios";

import { ARCHIVES_URL  } from "../config/app.api.config";

export function getArchivesCollection(page) {
  return axios.get(ARCHIVES_URL, {
    params: {
      page: page
    }
  });
}

export function deleteArchivesById(id) {
  return axios.delete(id);
}

export function addArchives(snapshots) {
  return axios.post(ARCHIVES_URL, { snapshots: snapshots });
}


export function editSnapshotByID(id) {

}