const rules = {
    ROLE_VISITOR: {
        static: [
            "auth:register"
        ]
    },
    ROLE_USER: {
        static: [
            "captures:listSelf",
            "captures:create",
            "users:getSelf",
            "test:role_user",
            "captures:delete"
        ],
        dynamic: {
            "captures:edit": ({userId, captureOwnerId}) => {
                if (!userId || !captureOwnerId) return false;
                return userId === captureOwnerId;
            },
            /*"captures:delete": ({userId, captureOwnerId}) => {
                if (!userId || !captureOwnerId) return false;
                return userId === captureOwnerId;
            }*/
        }
    },
    ROLE_ADMIN: {
        static: [
            "captures:list",
            "captures:listSelf",
            "captures:create",
            "captures:edit",
            "captures:delete",
            "users:get",
            "users:getSelf",
            "users:add",
            "test:role_admin",
        ],
        dynamic: {}
    }
};

export default rules;