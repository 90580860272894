/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";

import * as users from "../store/ducks/users.duck";

import {getUsersCollection, deleteUsersById} from "../crud/users.crud";

import {Table, Spinner, Row, Col} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

import Pagination from "../components/Pagination/Pagination";

import Can from "../components/Can/Can";

class UsersTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: this.props.users.page,
            total: this.props.users.total,
            perPage: this.props.users.perPage
        };
    }

    enableLoading = () => {
        //setLoading(true);
        this.setState({loading: true});
    };

    disableLoading = () => {
        this.setState({loading: false});
    };

    componentDidMount() {
        setTimeout(() => {
            this._getUsersCollection();
        }, 1000);
    }

    componentDidUpdate() {
        if (this.state.page !== this.props.users.page) {
            this.setState({page: this.props.users.page});

            this._getUsersCollection();
        }
    }

    _getUsersCollection() {
        this.enableLoading();
        getUsersCollection(this.props.users.page)
            .then(({data}) => {
                this.disableLoading();
                this.setState({total: data['hydra:totalItems']});
                this.props.requestCollection(data);
            })
            .catch((error) => {
                this.disableLoading();
                /*setSubmitting(false);
                setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                );*/
            });
    }

    _deleteUsersById(id) {
        this.enableLoading();
        deleteUsersById(id)
            .then( () => {
                this._getUsersCollection();
            })
            .catch((error) => {
                this.disableLoading();
            });
    }

    render() {

        const {
            users,
            usersAuth
        } = this.props;

        const loadingOverlayClass = this.state.loading ? "kt-portlet__loading-overlay" : "kt-portlet__loading-overlay hidden";

        return (
            <Can
                roles={usersAuth.roles}
                perform="users:get"
                yes={() => (
                    <>
                        <div className="kt-portlet kt-portlet--this.stateheight-fluid">
                            <div className={loadingOverlayClass}>
                                <Spinner animation="border" variant="dark"/>
                            </div>
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title"><FormattedMessage id="USERS.TABLE.TITLE"/></h3>
                                </div>
                                { /* <PortletHeaderDropdown /> */}
                            </div>
                            <div className="kt-portlet__body ">
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Email</th>
                                        <th>Access Key</th>
                                        <th>Plan</th>
                                        <th>Expired</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.users.map((item, index) => {

                                        const dateExpiredMoment = moment(item.licensedPlanExpired);

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input type="checkbox"/>
                                                </td>
                                                <td>{item.email}</td>
                                                <td>{item.accessKey}</td>
                                                <td>{item.licensedPlan.name}</td>
                                                <td>{dateExpiredMoment.format("MM.DD.YYYY")}<br/>{dateExpiredMoment.format("HH:mm:ss")}
                                                </td>
                                                <td><OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            Edit
                                                        </Tooltip>
                                                    }
                                                ><Link to={`/users/${item.id}`} className="btn btn-clean btn-md btn-icon">
                                                    <i className="la la-edit"/>
                                                </Link>
                                                </OverlayTrigger>

                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                Delete
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button
                                                            className="btn btn-clean btn-md btn-icon"
                                                            onClick={() => this._deleteUsersById(`/users/${item.id}`) }
                                                        >
                                                            <i className="la la-trash"/>
                                                        </button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>

                                <Pagination total={this.state.total} perPage={this.state.perPage} path="/users/page" page={this.state.page}/>
                            </div>
                        </div>
                    </>
                )}
            />
        );
    }
}

const mapStateToProps = store => ({
    users: store.users,
    usersAuth: store.auth.users
});

export default injectIntl(
    connect(
        mapStateToProps,
        users.actions
    )(UsersTable)
);

