import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PaginationItem, PaginationLink } from "reactstrap";

export default function PaginationPrev(props) {
    const { page, path } = props;

    useEffect(() => {

    });


    if(!page) {
        return null;
    }


    return (
        <PaginationItem>
            <PaginationLink previous tag={ Link } to={ path + '/' + page} >Prev</PaginationLink>
        </PaginationItem>
    );
}