import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects"
import { getSnapshotListItemCollection } from "../../crud/snapshotListItem.crud";

export const actionTypes = {
  CollectionRequested: "[Load snapshotListItem collection] Action",
  ChangePage: "[Change snapshotListItem page] Action",
  Refresh: "[Refresh snapshotListItem] Action"
};

const initialSnapshotListItemState = {
  snapshotListItem: [],
  total: 0,
  perPage: 30,
  page: 1
};
export const reducer = persistReducer(
    { storage, key: "snapshotListItem"},
    (state = initialSnapshotListItemState, action) => {
      switch (action.type) {
        case actionTypes.CollectionRequested: {
          const { snapshotListItem } = action.payload;
          console.log('123');
          return { ...state, snapshotListItem:snapshotListItem['hydra:member'], total: snapshotListItem['hydra:totalItems'] };
        }
        case actionTypes.ChangePage: {
          const { page } = action.payload
          return { ...state, page };
        }
        case actionTypes.Refresh: {
          return state;
        }

        default:
          return state;
      }
    }
);

export const actions = {
  requestCollection: snapshotListItem => ({
    type: actionTypes.CollectionRequested,
    payload: { snapshotListItem }
  }),
  changePage: page => ({
    type: actionTypes.ChangePage,
    payload: { page }
  }),
  refresh: () => ({
    type: actionTypes.Refresh,
    payload: {  }
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Refresh, function* addSaga() {
    const { data } = yield getSnapshotListItemCollection();
    console.log('321');
    yield put(actions.requestCollection(data));
  });
}