import React, { useEffect, useState } from "react";
import { getSnapshotsByID } from "../../crud/snapshots.crud";
import { SNAPSHOTS_URL } from "../../config/app.api.config";

import { Button} from "reactstrap";
import { Modal } from "react-bootstrap";

import moment from "moment";

import { toAbsoluteUrl } from "../../../_metronic/utils/utils";

import "../../../_metronic/_assets/sass/pages/snapshots/b-snapshots.scss";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

function Snapshots(props) {
    const [snapshot, setSnapshot] = useState(0);
    const [snapshotID, setSnapshotID] = useState(0);
    const [checkPopupState, setCheckPopupState] = useState(0);

    const { match } = props;

    const { intl } = props

    useEffect(() => {
        getSnapshotsByID (SNAPSHOTS_URL + '/' +match.params.id)
            .then( ({ data }) => {
                setSnapshot(data);
                //this.props.requestCollection(data);
            })
            .catch((error) => {
                /*setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                );*/
            });
    }, [match.params.id]);

    const handleCloseCheckPopup = () => {
        setCheckPopupState( false );
    }

    const handleShowCheckPopup = () => {
        setCheckPopupState( true );
    }

    if (!snapshot) {
        return <div>Snapshot not found</div>;
    }

    const iframe = snapshot.views.length ? <iframe src={ snapshot.views[0].viewUrl }></iframe> : "";

    return (
    <>
        <div className="b-snapshots">
            <div className="b-snapshots__header">
                <div className="container-fluid">
                    <div className="b-snapshots__header-content">
                        <a href="https://cappture.cc/" className="b-snapshots__logo">
                            <img src={toAbsoluteUrl("/media/logos/logo-dark.svg")} alt=""/>
                        </a>

                        <div className="b-snapshots__buttons">
                            <div className="b-snapshots__date">
                                {intl.formatMessage({id: "SNAPSHOT.LABEL.ARCHIVED_DATE"}, {
                                    date: moment(snapshot.timestamp).format('YYYY-MM-DD HH:mm:ss'),
                                })}
                            </div>
                            <a
                                href={ snapshot.url }
                                className="btn btn-outline-primary"
                                target="_blank" rel="noopener noreferrer">{intl.formatMessage({id: "SNAPSHOT.BUTTONS.ORIGINAL_URL"})}</a> <a
                            href={ snapshot.views[0].downloadUrl }
                            className="btn btn-outline-primary">{intl.formatMessage({id: "SNAPSHOT.BUTTONS.SAVE_ORIGIN"})}</a> <a
                            href={ snapshot.views[0].signatureUrl }
                            className="btn btn-outline-primary">{intl.formatMessage({id: "SNAPSHOT.BUTTONS.SAVE_SIGNATURE"})}</a> <Button
                            className="btn btn-outline-primary" onClick={handleShowCheckPopup}>{intl.formatMessage({id: "SNAPSHOT.BUTTONS.HOW_TO_CHECK_ORIGINAL_URL"})}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="b-snapshots__body">
                { iframe }
            </div>
        </div>
        <Modal
            show={ checkPopupState }
            onHide={handleCloseCheckPopup}
            dialogClassName="modal-90w"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Verify blockchain
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul>
                    <li>At first, save the
                        <a href={ snapshot.views[0].downloadUrl }
                           title="Save source file of selected type"
                           target="_blank"> source file</a>
                        and its
                        <a
                            href={ snapshot.views[0].signatureUrl }
                            title="Save the signature of the selected file"
                            target="_blank">signature (.ots file)</a></li>
                    <li>Follow the link: <a href="https://opentimestamps.org/#stamp-and-verify" target="_blank">Opentimestamps</a>
                    </li>
                    <li>Click VERIFY</li>
                    <li>Choose the file with the signature (.ots )</li>
                    <li>Choose the original file in the new block</li>
                    <li>Make sure of the signature is valid</li>
                </ul>
            </Modal.Body>
        </Modal>
        </>
    );
}

export default injectIntl(Snapshots);
