import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PaginationItem, PaginationLink } from "reactstrap";

export default function PaginationNext(props) {

    const { page, path } = props;

    useEffect(() => {

    });


    if(!page) {
        return null;
    }

    return (
        <PaginationItem>
            <PaginationLink next tag={ Link } to={ path + '/' + page}>Next</PaginationLink>
        </PaginationItem>
    );
}