import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as snapshotGroups from "../../store/ducks/snapshot_groups.duck";

import SnapshotGroupsTable from "../../widgets/SnapshotGroupsTable";
import AddSnapshotGroups from "./AddSnapshotGroups";
import {injectIntl} from "react-intl";

export function Groups(props) {

    useEffect(() => {
        const { match, page } = props;

        if(typeof match.params.page === "undefined"){
            props.changePage(1);
        } else if(page != match.params.page) {
            props.changePage(parseInt(match.params.page));
        }
    });

    return (
        <>
            <AddSnapshotGroups />

            <SnapshotGroupsTable />
        </>
    );
}

const mapStateToProps = store => ({
    page: store.snapshotGroups.page
});

export default withRouter(injectIntl(
    connect(
        mapStateToProps,
        snapshotGroups.actions
    )(Groups)
));