import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Input} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import CopyUrlButton from "../../components/Buttons/CopyUrlButton";
import {deleteSnapshotsById, editSnapshotByID} from "../../crud/snapshots.crud";
import Can from "../Can/Can";
import moment from "moment";

export function SnapshotListItem(props) {
    const [item, setItem] = useState(0);
    const [group, setGroup] = useState(0);

    useEffect(() => {
        setItem(props.item);
    }, [props.item]);


    if (!item) {
        return null;
    }

    const dateCreatedMoment = moment(item.timestamp);
    const url = new URL(item.url);

    let viewHTML,
        downloadScreenshot,
        downloadPDF;

    if (item.views.length) {
        viewHTML = typeof item.views[0].viewUrl === "undefined" ? "" : <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    View
                </Tooltip>
            }
        ><Link
            to={`/snapshots/${item.id}`}
            className="btn btn-clean btn-md btn-icon"
            target='_blank'
        >
            <i className="la la-eye"/>
        </Link></OverlayTrigger>;

        downloadPDF = typeof item.views[2].downloadUrl === "undefined" ? "" : <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    Get PDF
                </Tooltip>
            }
        ><a
            href={item.views[2].downloadUrl}
            className="btn btn-clean btn-md btn-icon"
        >
            <i className="la la-file-pdf-o"/>
        </a></OverlayTrigger>;

        downloadScreenshot = typeof item.views[1].downloadUrl === "undefined" ? "" : <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    Get PNG
                </Tooltip>
            }
        ><a
            href={item.views[1].downloadUrl}
            className="btn btn-clean btn-md btn-icon"
        >
            <i className="la la-file-image-o"/>
        </a></OverlayTrigger>;
    }

    return (
        <tr>
            <td>{dateCreatedMoment.format("DD.MM.YYYY")}<br/>{dateCreatedMoment.format("HH:mm:ss")}</td>
            <td style={{
                width: "30%"
            }}>{item.title}</td>
            <td style={{
                width: "10%"
            }}><a href={url.href} target={`_blank`}><img src={item.favicon}/> {url.origin}</a></td>
            <td>
                { /*<button className="btn btn-outline-dark btn-sm">Tag name</button>
                <button className="btn btn-outline-dark btn-sm">Tag name</button> */}
            </td>
            <td>
                {viewHTML}
                {downloadPDF}
                {downloadScreenshot}
            </td>
            <td>
                <CopyUrlButton url={`${window.location.origin}/snapshots/${item.id}`} />
            </td>
        </tr>
    );
}

const mapStateToProps = store => ({
    users: store.auth.users
});

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(SnapshotListItem)
);