import React, {useState, useEffect} from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import KTUtil from "../../../_metronic/_assets/js/util";
import decodeJwt from "jwt-decode";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import Invite from "./Invite";
import ForgotPassword from "./ForgotPassword";
import { FormattedMessage } from "react-intl";
import ScriptTag from "react-script-tag";

export default function AuthPage(props) {
  const [email, setEmail] = useState(false);
  const [token, setToken] = useState(false);
  const [registered, setRegistered] = useState(0);
console.log(props);
  useEffect(() => {
    let token = KTUtil.getURLParam('token');
    if(token) {
      const decodedToken = decodeJwt(token);

      setEmail(decodedToken.email);
      setToken(token);
    }

    if(typeof props.location.state !== "undefined" && typeof props.location.state.registered !== "undefined"){
      setRegistered(props.location.state.registered);
    }
  }, [props]);

  return (
    <>
      <ScriptTag type="text/javascript" src="//js.hs-scripts.com/7356453.js" />
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo_mini.svg")}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title"><FormattedMessage id="AUTH.PROMO.TITLE" /></h3>
                  <h4 className="kt-login__subtitle">
                    <FormattedMessage id="AUTH.PROMO.SUBTITLE" />
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; 2020 Cappture
                  </div>
                  <div className="kt-login__menu">
                    {/*<Link to="/terms" className="kt-link">
                      Privacy
                    </Link>*/}
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route path="/auth/login" component={() => <Login registered={registered}/>} />

                { email &&
                  <Route path="/auth/registration" component={() => <Registration email={email} token={token}/>}/>
                }

                <Route path="/auth/registration" component={Invite} />

                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
