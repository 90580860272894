import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Input} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyUrlButton from "../../components/Buttons/CopyUrlButton";
import {deleteSnapshotsById, editSnapshotByID, getSnapshotsByID} from "../../crud/snapshots.crud";
import Can from "../Can/Can";
import moment from "moment";

import { SNAPSHOTS_URL } from "../../config/app.api.config";

export function SnapshotListItem(props) {
    const [item, setItem] = useState(0);
    const [group, setGroup] = useState(0);
    const [onChange, setOnChange] = useState(() => {});

    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    useEffect(() => {
        const refreshProcessing = () => {
            if(typeof item !== "undefined" && item.status === "PROCESSING") {
                setTimeout(() => {
                    getSnapshotsByID (SNAPSHOTS_URL + '/' +item.id)
                        .then( ({ data }) => {
                            if(data.status === "PROCESSING") {
                                refreshProcessing();
                            } else {
                                setItem(data);
                            }
                        })
                        .catch((error) => {

                        });
                }, 10000);
            }
        }

        refreshProcessing();
    }, [item]);


    useEffect(() => {
        setOnChange(props.onChange);
    }, [props.onChange]);


    if (!item) {
        return null;
    }

    const {users} = props;

    const _getUserLink = (user) => {
        if (user) {
            return user.email;
        }

        return;
    }

    const _deleteSnapshotsById = (id) => {
        //this.enableLoading();
        deleteSnapshotsById(id)
            .then(() => {
                onChange();
            })
            .catch((error) => {
                //this.disableLoading();
            });
    }

    const urlCopyClick = (text, result) => {
        alert('Url was copied!');
    }

    const _removeSnapshotFromGroup = (id) => {
        editSnapshotByID(id, {snapshotGroup: null}).then(({data}) => {
            setItem(data);
            onChange();
        })
        .catch((error) => {
            alert('Snapshot not removed from the group');
        });
    }


    const dateCreatedMoment = moment(item.timestamp);
    const url = new URL(item.url);

    let viewHTML,
        downloadScreenshot,
        downloadPDF;

    if (item.views.length) {
        viewHTML = typeof item.views[0].viewUrl === "undefined" ? "" : <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    View
                </Tooltip>
            }
        ><Link
            to={`/snapshots/${item.id}`}
            className="btn btn-clean btn-md btn-icon"
            target='_blank'
        >
            <i className="la la-eye"/>
        </Link></OverlayTrigger>;

        downloadPDF = typeof item.views[2].downloadUrl === "undefined" ? "" : <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    Get PDF
                </Tooltip>
            }
        ><a
            href={item.views[2].downloadUrl}
            className="btn btn-clean btn-md btn-icon"
        >
            <i className="la la-file-pdf-o"/>
        </a></OverlayTrigger>;

        downloadScreenshot = typeof item.views[1].downloadUrl === "undefined" ? "" : <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    Get PNG
                </Tooltip>
            }
        ><a
            href={item.views[1].downloadUrl}
            className="btn btn-clean btn-md btn-icon"
        >
            <i className="la la-file-image-o"/>
        </a></OverlayTrigger>;
    }

    const removeFromGroupButton = item.snapshotGroup && item.status === "COMPLETED" ? <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip>
                Remove snapshot from group
            </Tooltip>
        }
    >
        <button
            type="button"
            className="btn btn-clean btn-md btn-icon"
            onClick={() => _removeSnapshotFromGroup(item['@id'])}
        >
            <i className="la la-folder-open"/>
        </button>
    </OverlayTrigger> : "";

    const removeSnapshot = item.user ? <Can
        roles={users.roles}
        perform="captures:delete"
        yes={() => (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip>
                        Delete
                    </Tooltip>
                }
            >
                <button
                    type="button"
                    className="btn btn-clean btn-md btn-icon"
                    onClick={() => _deleteSnapshotsById(item['@id'])}
                    disabled={ item.status !== "COMPLETED" ? true : false }
                >
                    <i className="la la-trash"/>
                </button>
            </OverlayTrigger>
        )}
    /> : "" ;
    const trClasses = item.status === "PROCESSING" ? "status_processing" : "" ;

    return (
        <tr className={trClasses}>
            <td className={'align-middle'}>
                <Input
                    type="checkbox"
                    name="snapshots"
                    checked={props.isSelected}
                    onChange={props.onChangeCheckbox}
                    value={item['@id']}
                />
            </td>
            <td className={'align-middle'}>{dateCreatedMoment.format("DD.MM.YYYY")}<br/>{dateCreatedMoment.format("HH:mm:ss")}</td>
            <td className={'align-middle'} style={{
                width: "30%"
            }}>{ item.status === "PROCESSING" ? <i>Snapshot is being processed</i> : item.title }</td>
            <td className={'align-middle'} style={{
                width: "10%"
            }}><a href={url.href} target={`_blank`}>{ item.status === "COMPLETED" ? <img src={item.favicon}/> : "" } {url.origin}</a></td>
            <td className={'align-middle'}>
                { /*<button className="btn btn-outline-dark btn-sm">Tag name</button>
                <button className="btn btn-outline-dark btn-sm">Tag name</button> */}
            </td>
            <Can
                roles={users.roles}
                perform="captures:list"
                yes={() => (
                    <td className={'align-middle'}>{_getUserLink(item.user)}</td>
                )}
            />
            <td className={'align-middle'}>
                {viewHTML}
                { /* <Link
                                                    to={`/snapshots/${ item.id }/zip`}
                                                    className="btn btn-clean btn-md btn-icon"
                                                >
                                                    <i className="la la-file-archive-o" />
                                                </Link> */}
                {downloadPDF}
                {downloadScreenshot}
                {removeFromGroupButton}
                {removeSnapshot}
            </td>
            <td className={'align-middle'}>
                <CopyUrlButton url={`${window.location.origin}/snapshots/${item.id}`} />
            </td>
        </tr>
    );
}

const mapStateToProps = store => ({
    users: store.auth.users
});

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(SnapshotListItem)
);