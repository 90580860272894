import axios from "axios";

import { SNAPSHOTLISTITEM_URL  } from "../config/app.api.config";

export function getSnapshotListItemCollection(page = 1) {
  return axios.get(SNAPSHOTLISTITEM_URL, {
    params: {
      page: page
    }
  });
}