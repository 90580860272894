import React from "react";
import {injectIntl} from "react-intl";
import {Formik} from "formik";

import {addRegistration } from "../../crud/registration.crud";

import { Col, Row, Button, Form, FormGroup, Label, Input, Alert, FormFeedback, Spinner } from "reactstrap";

export class InviteUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            inviteValue: ""
        };
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    enableLoading() {
        //setLoading(true);
        //this.setState({loading: true});
    };

    disableLoading() {
        this.setState({loading: false});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        this.enableLoading();

    }

    handleChange = (event) => {
        this.setState({inviteValue: event.target.value});
    }

    render() {
        const loadingOverlayClass = this.state.loading ? "kt-portlet__loading-overlay" : "kt-portlet__loading-overlay hidden";

        return (
            <Formik
                initialValues={{
                    email: ""
                }}
                validate={values => {
                    const errors = {};

                    if (!values.email) {
                        errors.email = "Field is required";
                        /*errors.email = this.props.formatMessage({
                            id: "FORM.VALIDATION.REQUIRED_FIELD"
                        });*/
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = "Please enter valid email";
                        /*errors.email = this.props.formatMessage({
                            id: "FORM.VALIDATION.INVALID_EMAIL"
                        });*/
                    }
                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {

                    setTimeout(() => {
                        addRegistration(values.email, true)
                            .then( ( { data } ) => {
                                setSubmitting(false);
                                alert("Invite was sent to " +data.email);
                                resetForm({values: ""});
                            })
                            .catch((error) => {
                                alert(error.response.data['hydra:description']);
                                setSubmitting(false);
                                /*setStatus(
                                    intl.formatMessage({
                                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                                    })
                                );*/
                            });
                    }, 1000);
                }}
            >
                {({
                      values,
                      status,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                  }) => (
                    <>
                        <Form onSubmit={handleSubmit} noValidate autoComplete="off" inlines="true" style={{
                            "justifyContent": "flex-end",
                            "display": "flex",
                        }}>
                            {status && (
                                <Alert color="danger">
                                    {status}
                                </Alert>
                            )}
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{
                                "width": "calc(100% - 150px)"
                            }}>
                                <Input
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    disabled={ false }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    invalid={Boolean(touched.email && errors.email)}

                                    style={{
                                        "width": "100%"
                                    }}
                                />
                                {touched.email && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                            </FormGroup>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary"
                                style={
                                {
                                    "width": 140
                                }
                            }>Invite User</Button>
                        </Form>
                    </>
                )}
            </Formik>
        );
    }
}

export default InviteUser;