import rules from "../../config/rbac-rules.config";

const check = (rules, roles, action, data) => {

    if(!action) {
        return true;
    }

    let permissions = {
        static: [],
        dynamic: {}
    };
    roles.forEach(role => {
        permissions.static = [...permissions.static, ...rules[role].static];
        permissions.dynamic = {...permissions.dynamic, ...rules[role].dynamic};
    });

    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true;
    }

    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
            // dynamic rule not provided for action
            return false;
        }

        return permissionCondition(data);
    }
    return false;
};

const Can = props =>
    check(rules, props.roles, props.perform, props.data)
        ? props.yes()
        : props.no();

Can.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Can;