import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as archives from "./ducks/archives.duck";
import * as snapshots from "./ducks/snapshots.duck";
import * as snapshotGroups from "./ducks/snapshot_groups.duck";
import * as users from "./ducks/users.duck";
import * as snapshotListItem from "./ducks/snapshotListItem.duck";

import { metronic } from "../../_metronic";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  archives: archives.reducer,
  snapshots: snapshots.reducer,
  users: users.reducer,
  snapshotGroups: snapshotGroups.reducer,
  snapshotListItem: snapshotListItem.reducer
});

export function* rootSaga() {
  yield all([auth.saga(), snapshotGroups.saga(), snapshotListItem.saga(), snapshots.saga()]);
}
