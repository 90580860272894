import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as account from "../../store/ducks/users.duck";
import { updateUsersById } from "../../crud/users.crud";
import Can from "../../components/Can/Can";

import { Col, Row, Button, Form, FormGroup, Label, Input, Alert, FormFeedback } from 'reactstrap';

function ProfilePage(props) {
    const { intl } = props;

    const {
        users
    } = props;

    return (
        <div className="kt-login__body">
            <div className="kt-login__form">
                <div className="kt-login__title">
                    <h3>
                        <FormattedMessage id="PROFILE.PAGE.TITLE" />
                    </h3>
                </div>

                <Formik
                    initialValues={{
                        email: users.email,
                        password: "",
                        confirmPassword: ""
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.email) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_FIELD"
                            });
                        }


                        if (!values.password) {
                            errors.password = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.confirmPassword) {
                            errors.confirmPassword = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (values.password !== values.confirmPassword) {
                            errors.confirmPassword =
                                "Password and Confirm Password didn't match.";
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        updateUsersById(
                            users.id,
                            values.email,
                            values.password
                        )
                            .then(({ data }) => {
                                //props.register(accessToken);
                            })
                            .catch(() => {
                               /* setSubmitting(false);
                                setStatus(
                                    intl.formatMessage({
                                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                                    })
                                );*/
                            });
                    }}
                >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                          <>
                        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                            {status && (
                                <Alert color="danger">
                                    {status}
                                </Alert>
                            )}
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Can
                                            roles={users.roles}
                                            perform={"users:get"}
                                            yes={() => (
                                                <>
                                                <Input
                                                    type="email"
                                                    placeholder="Email"
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    invalid={Boolean(touched.email && errors.email)}
                                                />
                                                {touched.email && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                                                </>
                                            )}
                                            no={() => (
                                                <Input
                                                    type="email"
                                                    placeholder="Email"
                                                    value={values.email}
                                                    disabled={true}
                                                />
                                            )}
                                        />

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="password">Password</Label>
                                        <Input
                                            type="password"
                                            label="Password"
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            invalid={Boolean(touched.password && errors.password)}
                                             />
                                        {touched.password && errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="confirmPassword">Confirm Password</Label>
                                        <Input
                                            type="password"
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                            invalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                        />
                                        {touched.confirmPassword && errors.confirmPassword && <FormFeedback>{errors.confirmPassword}</FormFeedback>}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary btn-elevate kt-login__btn-primary"
                            >
                                Update
                            </Button>
                        </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
}

const mapStateToProps = store => ({
    users: store.auth.users
});

export default injectIntl(
    connect(
        mapStateToProps,
        account.actions
    )(ProfilePage)
);
