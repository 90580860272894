import React, {useEffect, useState} from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as users from "../../store/ducks/users.duck";
import { addUsers } from "../../crud/users.crud";
import { getPlans } from "../../crud/plans.crud";
import Can from "../../components/Can/Can";

import {Col, Row, Button, Form, FormGroup, Label, Input, Alert, FormFeedback, Spinner, Table} from 'reactstrap';
import moment from "moment";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";

function AddUser(props) {

    const [plans, setPlans] = useState([]);

    useEffect(() => {
        getPlans()
            .then(({data}) => {
                setPlans(data['hydra:member']);
            })
            .catch((error) => {
                /*setSubmitting(false);
                setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                );*/
            });
    }, [props]);


    const { intl } = props;

    const {
        usersAuth
    } = props;


    return (
        <>
        <Can
            roles={usersAuth.roles}
            perform="users:add"
            yes={() => (
                <>
                    <div className="kt-portlet kt-portlet--this.stateheight-fluid">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title"><FormattedMessage id="USERS.ADD.TITLE"/></h3>
                            </div>
                            { /* <PortletHeaderDropdown /> */}
                        </div>
                        <div className="kt-portlet__body ">
                            <Formik
                                initialValues={{
                                    email: "",
                                    password: "",
                                    confirmPassword: "",
                                    plan: "/plans/1",
                                    name: "",
                                    roles: ["ROLE_USER"]
                                }}
                                validate={values => {
                                    const errors = {};

                                    if (!values.email) {
                                        errors.email = intl.formatMessage({
                                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                        });
                                    } else if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                    ) {
                                        errors.email = intl.formatMessage({
                                            id: "AUTH.VALIDATION.INVALID_FIELD"
                                        });
                                    }


                                    if (!values.password) {
                                        errors.password = intl.formatMessage({
                                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                        });
                                    }

                                    /*if (!values.confirmPassword) {
                                        errors.confirmPassword = intl.selected formatMessage({
                                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                        });
                                    } else if (values.password !== values.confirmPassword) {
                                        errors.confirmPassword =
                                            "Password and Confirm Password didn't match.";
                                    }*/

                                    return errors;
                                }}
                                onSubmit={(values, { setStatus, setSubmitting }) => {

                                    setTimeout(() => {
                                        addUsers(values)
                                            .then( ( { data } ) => {
                                                alert(data.email + " was added");
                                            })
                                            .catch((error) => {
                                                console.log(error.response);

                                                /*setStatus(
                                                    intl.formatMessage({
                                                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                                                    })
                                                );*/
                                            });
                                    }, 1000);
                                }}
                            >
                                {({
                                      values,
                                      status,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting
                                  }) => (
                                    <>
                                        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                                            {status && (
                                                <Alert color="danger">
                                                    {status}
                                                </Alert>
                                            )}
                                            <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="email">Email</Label>
                                                        <Input
                                                            type="email"
                                                            placeholder="Email"
                                                            name="email"
                                                            disabled={ false }
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.email}
                                                            invalid={Boolean(touched.email && errors.email)}
                                                        />
                                                        {touched.email && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="name">Name</Label>
                                                        <Input
                                                            type="text"
                                                            label="Name"
                                                            name="name"
                                                            disabled={isSubmitting}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.name}
                                                            invalid={Boolean(touched.name && errors.name)}
                                                        />
                                                        {touched.name && errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="roles">Roles</Label>
                                                        <Input
                                                            type="select"
                                                            name="roles"
                                                            disabled={isSubmitting}
                                                            multiple
                                                            onChange={handleChange}
                                                            value={values.roles}
                                                            invalid={Boolean(touched.roles && errors.roles)}
                                                        >
                                                            <option value="ROLE_USER">User</option>
                                                            <option value="ROLE_ADMIN">Admin</option>
                                                        </Input>
                                                        {touched.rolse && errors.roles && <FormFeedback>{errors.roles}</FormFeedback>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="roles">Plan</Label>
                                                        <Input
                                                            type="select"
                                                            name="plan"
                                                            disabled={isSubmitting}
                                                            onChange={handleChange}
                                                            value={values.plan}
                                                        >
                                                            {plans.map((item, index) =>
                                                                <option key={index} value={item['@id']}>{item.name}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="password">Password</Label>
                                                        <Input
                                                            type="password"
                                                            label="Password"
                                                            name="password"
                                                            disabled={isSubmitting}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.password}
                                                            invalid={Boolean(touched.password && errors.password)}
                                                        />
                                                        {touched.password && errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="confirmPassword">Confirm Password</Label>
                                                        <Input
                                                            type="password"
                                                            label="Confirm Password"
                                                            name="confirmPassword"
                                                            disabled={isSubmitting}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.confirmPassword}
                                                            invalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                                        />
                                                        {touched.confirmPassword && errors.confirmPassword && <FormFeedback>{errors.confirmPassword}</FormFeedback>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="btn btn-primary btn-elevate kt-login__btn-primary"
                                            >
                                                Add user
                                            </Button>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </>
            )}
        />
    </>
    );
}

const mapStateToProps = store => ({
    usersAuth: store.auth.users
});

export default injectIntl(
    connect(
        mapStateToProps,
        users.actions
    )(AddUser)
);
