import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import { put, takeLatest } from "redux-saga/effects";
//import { getUserByToken } from "../../crud/snapshots.crud";

export const actionTypes = {
  CollectionRequested: "[Load archives collection] Action",
  Delete: "[Delete] Action",
  ChangePage: "[Change page] Action"
};

const initialArchivesState = {
  archives: [],
  total: 0,
  perPage: 30,
  page: 1
};

export const reducer = persistReducer(
    { storage, key: "archives"},
    (state = initialArchivesState, action) => {
      switch (action.type) {
        case actionTypes.CollectionRequested: {
          const { archives } = action.payload;

          return { ...state, archives:archives['hydra:member'], total: archives['hydra:totalItems'] };
        }

        case actionTypes.Delete: {
          return state;
        }

        case actionTypes.ChangePage: {
          const { page } = action.payload
          return { ...state, page };
        }

        default:
          return state;
      }
    }
);

export const actions = {
  requestCollection: archives => ({
    type: actionTypes.CollectionRequested,
    payload: { archives }
  }),
  delete: () => ({
    type: actionTypes.Delete
  }),
  changePage: page => ({
    type: actionTypes.ChangePage,
    payload: { page }
  }),
};
