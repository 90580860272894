import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import { put, takeLatest } from "redux-saga/effects";
//import { getUserByToken } from "../../crud/auth.crud";

export const actionTypes = {
    CollectionRequested: "[Load users collection] Action",
    Update: "[Update users] Action",
    Delete: "[Delete users] Action",
    Add: "[Add users] Action",
    ChangePage: "[Change page] Action"
};

const initialUsersState = {
    users: [],
    total: 0,
    perPage: 30,
    page: 1
};

export const reducer = persistReducer(
    { storage, key: "users"},
    (state = initialUsersState, action) => {
      switch (action.type) {
        case actionTypes.CollectionRequested: {
          const { users } = action.payload;
          return { ...state, users:users['hydra:member'], total: users['hydra:totalItems'] };
        }

        case actionTypes.Update: {
          const { users } = action.payload;

          return { users };
        }

        case actionTypes.Delete: {
          return state;
        }

        case actionTypes.ChangePage: {
          const { page } = action.payload
          return { ...state, page };
        }

        default:
          return state;
      }
    }
);

export const actions = {
    requestCollection: users => ({
        type: actionTypes.CollectionRequested,
        payload: { users }
    }),
    update: users => ({
        type: actionTypes.Update,
        payload: { users }
    }),
    delete: () => ({
        type: actionTypes.Delete
    }),
    changePage: page => ({
        type: actionTypes.ChangePage,
        payload: { page }
    })
};