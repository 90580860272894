import React, {useState} from "react";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { Link, Redirect} from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { addUsers } from "../../crud/users.crud";

function Registration(props) {
  const [registered, setRegistered] = useState(false);

  const { intl } = props;

  if(registered) {
    return <Redirect
        to={{
          pathname: "/auth/login",
          state: { registered: 1 }
        }}
    />
  }

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            {intl.formatMessage({ id: "AUTH.REGISTER.TITLE" }, { email: props.email, lineBreak: <br /> })}
          </h3>
        </div>

        <Formik
          initialValues={{
            email: props.email,
            name: "",
            surname: "",
            password: "",
            acceptTerms: true,
            confirmPassword: ""
          }}
          validate={values => {
            const errors = {};

            if (!values.name) {
              errors.name = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.surname) {
              errors.surname = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Password and Confirm Password didn't match.";
            }

            if (!values.acceptTerms) {
              errors.acceptTerms = "Accept Terms";
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setTimeout(() => {
              addUsers(values, props.token)
                  .then( ( { data } ) => {
                    setRegistered(true);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    console.log(error.response);
                    if(error.response.status === 401) {
                      setStatus(
                          error.response.data.message
                      );
                    } else if(error.response.status === 400) {
                      setStatus(
                          error.response.data['hydra:description']
                      );

                    } else {
                      setStatus(
                          "Something went wrong. Please contact with administrator"
                      );
                    }

                  });
            }, 1000);
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group mb-0">
                <TextField
                  margin="normal"
                  label={intl.formatMessage({ id: "AUTH.INPUT.NAME" })}
                  className="kt-width-full"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullname}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                    margin="normal"
                    label={intl.formatMessage({ id: "AUTH.INPUT.SURNAME" })}
                    className="kt-width-full"
                    name="surname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fullname}
                    helperText={touched.surname && errors.surname}
                    error={Boolean(touched.surname && errors.surname)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  type="password"
                  margin="normal"
                  label={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                  className="kt-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="form-group">
                <TextField
                  type="password"
                  margin="normal"
                  label={intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })}
                  className="kt-width-full"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                />
              </div>

              <div className="form-group mb-0">
                <FormControlLabel
                  label={
                    <>
                      {intl.formatMessage({ id: "AUTH.GENERAL.AGREE" })}
                      <Link
                        to="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.formatMessage({ id: "AUTH.GENERAL.TERMS_AND_CONDITIONS" })}
                      </Link>
                    </>
                  }
                  control={
                    <Checkbox
                      color="primary"
                      name="acceptTerms"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={values.acceptTerms}
                    />
                  }
                />
              </div>

              <div className="kt-login__actions">
                <Field type="hidden" name="email" />
                <button
                  disabled={isSubmitting || !values.acceptTerms}
                  className="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                  {intl.formatMessage({ id: "AUTH.REGISTER.BUTTON" })}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(Registration);
