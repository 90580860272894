import axios from "axios";

import { PLANS_URL  } from "../config/app.api.config";

export function getPlans(page = 1) {
  return axios.get( PLANS_URL, {
    params: {
      page: page
    }
  });
}