import React, {useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import {Col, Input} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { updateSnapshotGroupsByID } from "../../crud/snapshot_groups.crud";
import CopyToClipboard from "react-copy-to-clipboard";

export default function ToggleGroupVisibility(props) {
    const [url, setUrl] = useState(0);
    const [isCopied, setIsCopied] = useState(0);

    useEffect(() => {
        setUrl(props.url);
    }, [props.url]);

    const urlCopyClick = (text, result) => {
        if (result) {
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        }
    }

    if(isCopied) {
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip>
                        URL was copied
                    </Tooltip>
                }
            >
                <CopyToClipboard
                    text={url}
                    onCopy={(text, result) => urlCopyClick(text, result)}
                >
                    <button
                        type="button"
                        className="btn btn-clean btn-md btn-icon"
                    >
                        <i className="la la-check"/>
                    </button>
                </CopyToClipboard>
            </OverlayTrigger>
        );
    }

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    Copy URL
                </Tooltip>
            }
        >
            <CopyToClipboard
                text={url}
                onCopy={(text, result) => urlCopyClick(text, result)}
            >
                <button
                    type="button"
                    className="btn btn-clean btn-md btn-icon"
                >
                    <i className="la la-copy"/>
                </button>
            </CopyToClipboard>
        </OverlayTrigger>
    );
}