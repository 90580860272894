import React, {useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import {Input} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { updateSnapshotGroupsByID } from "../../crud/snapshot_groups.crud";

export default function ToggleGroupVisibility(props) {
    const [group, setGroup] = useState(0);
    const [isPublic, setIsPublic] = useState(0);

    useEffect(() => {
        setGroup(props.group);
        setIsPublic(props.group.isPublic);
        console.log(props.group);
    }, [props.group]);

    const _updateSnapshotGroupsByID = (isPublic) => {
        console.log(isPublic);
        updateSnapshotGroupsByID(group['@id'], { isPublic: isPublic })
            .then(( {data} ) => {
                setIsPublic(data.isPublic);
            })
            .catch((error) => {
                alert('Something whent wrong');
            });
    }

    if(isPublic) {
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip>
                        Public
                    </Tooltip>
                }
            >
                <button
                    type="button"
                    className="btn btn-clean btn-md btn-icon"
                    onClick={() => _updateSnapshotGroupsByID(false)}
                >
                    <i className="la la-unlock-alt"/>
                </button>
            </OverlayTrigger>
        );
    }

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    Private
                </Tooltip>
            }
        >
            <button
                type="button"
                className="btn btn-clean btn-md btn-icon"
                onClick={() => _updateSnapshotGroupsByID(true)}
            >
                <i className="la la-unlock"/>
            </button>
        </OverlayTrigger>
    );
}