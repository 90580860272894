import React from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

import { addArchives } from "../../crud/archives.crud";
import { editSnapshotByID } from "../../crud/snapshots.crud";
import * as snapshotListItem from "../../store/ducks/snapshotListItem.duck";

import { Col, Row, Button, Form, FormGroup, Label, Input, Alert, FormFeedback, Spinner } from "reactstrap";

export class AddArchives extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            snapshotsValue: "",
            group: null,
            callback: null
        };
    }

    componentDidMount() {
        this.setState({group: this.props.group});
    }

    componentDidUpdate() {
        if (this.state.group !== this.props.group) {
            this.setState({group: this.props.group});
        }
    }

    enableLoading() {
        //setLoading(true);
        this.setState({loading: true});
    };

    disableLoading() {
        this.setState({loading: false});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        this.enableLoading();

        const snapshotsList = [];
        const groupId = typeof this.state.group !== "undefined" ? this.state.group['@id'] : null;

        this.state.snapshotsValue.split("\n").map((item) => {
            snapshotsList.push(
                {
                    "url": item,
                    "snapshotGroup": groupId
                }
            );
        });

        addArchives(snapshotsList)
            .then( ({ data }) => {
                this.disableLoading();
                this.setState({ snapshotsValue: "" });

                if(typeof this.props.callback === "function") {
                    this.props.callback();
                } else {
                    this.props.refresh();
                }
            })
            .catch((error) => {
                this.disableLoading();
                if(error.response.status === 400) {
                    alert('Snapshot not created. Error: ' + error.response.data['hydra:description']);
                }
            });

    }

    handleChange = (event) => {
        this.setState({snapshotsValue: event.target.value});
    }

    render() {
        const loadingOverlayClass = this.state.loading ? "kt-portlet__loading-overlay" : "kt-portlet__loading-overlay hidden";

        return (
            <Form onSubmit={this.handleSubmit} noValidate autoComplete="off" inlines="true" style={{
                "justifyContent": "flex-end",
                "display": "flex"
            }}>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{
                    "width": "calc(100% - 150px)"
                }}>
                    <Input
                        type="text"
                        name="snapshots"
                        id="snapshots"
                        placeholder="https://"
                        value={this.state.snapshotsValue}
                        onChange={this.handleChange}
                        style={{
                            "width": "100%"
                        }}
                    />
                </FormGroup>
                <Button style={
                    {
                        "width": 140
                    }
                }>Add cappture</Button>
            </Form>
        );
    }
}

export default injectIntl(
    connect(
        null,
        snapshotListItem.actions
    )(AddArchives)
);