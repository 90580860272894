import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";

class UserInfo extends React.Component {
    render() {
        const {
            users
        } = this.props;

        if(typeof users === "undefined") {
            return null;
        }

        return (
            <>
                <div className="kt-header__topbar-item kt-header__topbar-item--text">
                    <div className="kt-header__topbar-wrapper">
                        <div className="kt-header__topbar-text">
                            Plan: { users.licensedPlan.name }
                        </div>
                    </div>
                </div>

                <div className="kt-header__topbar-item kt-header__topbar-item--text">
                    <div className="kt-header__topbar-wrapper">
                        <div className="kt-header__topbar-text">
                            Expires: { moment(users.licensedPlanExpired).format("D.MM.YYYY") }
                        </div>
                    </div>
                </div>

                <div className="kt-header__topbar-item kt-header__topbar-item--text">
                    <div className="kt-header__topbar-wrapper">
                        <div className="kt-header__topbar-text">
                            Snapshots: { users.snapshotsCountPerInterval }/{ users.availableSnapshots }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth: { users } }) => ({
    users
});

export default connect(mapStateToProps)(UserInfo);
