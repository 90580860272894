import React from "react";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import UserInfo from "../header/UserInfo";

import { Button } from 'reactstrap';

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar">
          { /* <SearchDropdown useSVG="true" /> */ }

          { /* <UserNotifications
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
          pulse="true"
          pulseLight="false"
          skin="dark"
          iconType=""
          type="success"
          useSVG="true"
          dot="false"
        /> */ }

          { /*<QuickActionsPanel
          bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
          skin="dark"
          iconType=""
          useSVG="true"
          gridNavSkin="light"
        /> */ }

          { /*<MyCart
          iconType=""
          useSVG="true"
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
        /> */}

          { /*<QuickPanelToggler /> */ }

            <UserInfo />

              <div className="kt-header__topbar-item kt-header__topbar-item--text">
                  <div className="kt-header__topbar-wrapper">
                      <div className="kt-header__topbar-text">
                          <a href="https://share.hsforms.com/1NZzEFIy-RYu6fcC-qdWwLQ4do9x" className="btn btn-danger" target="_blank">Support</a>
                      </div>
                  </div>
              </div>


            <LanguageSelector iconType="" />

        <UserProfile showAvatar={true} showHi={true} showBadge={false} />
      </div>
    );
  }
}
