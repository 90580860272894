export const BASE_URL = 'https://api.cpt.re';
export const URL_PATH = 'https://api.cappture.cc';

/* Token */
export const AUTHENTICATION_TOKEN_ULR = URL_PATH + '/authentication_token';

/* Snapshots */
export const SNAPSHOTS_URL = URL_PATH + "/snapshots";

/* Archives */
export const ARCHIVES_URL = URL_PATH + "/archives";

/* Users */
export const USERS_URL = URL_PATH + "/users";

/* SnapshotGroups */
export const SNAPSHOT_GROUPS_URL = URL_PATH + "/snapshot_groups";

/* SnapshotGroups */
export const SNAPSHOTLISTITEM_URL = URL_PATH + "/snapshot_list_items";

/* Plans */
export const PLANS_URL = URL_PATH + "/plans";

/* Registration */
export const REGISTRATION_URL = URL_PATH + "/registrations";