import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {injectIntl} from "react-intl";

import * as snapshots from "../../store/ducks/snapshots.duck";

import SnapshotsTable from "../../widgets/SnapshotsTable";
import AddArchives from "./AddArchives";
import {Col, Row} from "reactstrap";


export function Snapshots(props) {

    useEffect(() => {
        const { match, page } = props;

        if(typeof match.params.page !== "undefined" && page != match.params.page) {
            props.changePage(parseInt(match.params.page));
        }
    });

    return (
        <>
            <Row>
                <Col sm={{ size: 6, offset: 6 }} className="clearfix" style={{
                    paddingBottom: 25
                }}>
                    <AddArchives />
                </Col>
            </Row>

            <SnapshotsTable />
        </>
    );
}

const mapStateToProps = store => ({
    page: store.snapshots.page
});

export default withRouter(injectIntl(
    connect(
        mapStateToProps,
        snapshots.actions
    )(Snapshots)
));