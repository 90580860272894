import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as snapshotListItem from "../../store/ducks/snapshotListItem.duck";

import SnapshotListItemTable from "../../widgets/SnapshotListItemTable";
import AddArchives from "../snapshots/AddArchives";
import InviteUser from "../../components/InviteUser/InviteUser";
import AddSnapshotGroups from "../snapshotGroups/AddSnapshotGroups";

import {injectIntl} from "react-intl";
import {Col, Row} from "reactstrap";

export function Dashboard(props) {

  useEffect(() => {
      props.changePage(1);
  });

  return (
    <>
        <Row>
            <Col sm={{ size: 6 }} className="clearfix" style={{
                paddingBottom: 25
            }}>
                <InviteUser />
            </Col>
            <Col sm={{ size: 6 }} className="clearfix" style={{
                paddingBottom: 25
            }}>
                <AddArchives />
            </Col>
        </Row>

        <AddSnapshotGroups />

        <SnapshotListItemTable />
    </>
  );
}

const mapStateToProps = store => ({
    page: store.snapshotListItem.page
});

export default withRouter(injectIntl(
    connect(
        mapStateToProps,
        snapshotListItem.actions
    )(Dashboard)
));