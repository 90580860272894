import axios from "axios";
import LocalStorageService from "../store/services/LocalStorageService";
import decodeJwt from "jwt-decode";

import { AUTHENTICATION_TOKEN_ULR  } from "../config/app.api.config";


import { toAbsoluteUrl } from "../../_metronic/utils/utils";

const localStorageService = LocalStorageService.getService();

export function login(username, password) {
  return axios.post(AUTHENTICATION_TOKEN_ULR , { username, password });
}

export function register(email, fullname, username, password) {

}

export function requestPassword(email) {

}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  //return instance.get(BASE_URL + ME_URL);
    const access_token = localStorageService.getAccessToken('access_token')

    const decodedToken = decodeJwt(access_token);

  return {data: {
      id: decodedToken.id,
      username: decodedToken.username,
      password: "demo",
      email: decodedToken.username,
      accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
      refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
      roles: decodedToken.roles,
      pic: toAbsoluteUrl("/media/users/default.jpg"),
      fullname: decodedToken.username,
      occupation: "CEO",
      companyName: "Keenthemes",
      phone: "456669067890",
      address: {
        addressLine: "L-12-20 Vertex, Cybersquare",
        city: "San Francisco",
        state: "California",
        postCode: "45000"
      },
      socialNetworks: {
        linkedIn: "https://linkedin.com/admin",
        facebook: "https://facebook.com/admin",
        twitter: "https://twitter.com/admin",
        instagram: "https://instagram.com/admin"
      }
    }
  };
}
