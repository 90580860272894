import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Input} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { updateSnapshotGroupsByID, getSnapshotGroupsSnapshotsCollection } from "../../crud/snapshot_groups.crud";
import * as snapshots from "../../store/ducks/snapshots.duck";

export function ClearGroup(props) {
    const [group, setGroup] = useState(0);
    const [onClear, setOnClear] = useState(() => {});

    useEffect(() => {
        setGroup(props.group);
    }, [props.group]);

    useEffect(() => {
        setOnClear(props.onClear);
    }, [props.onClear]);

    const _updateSnapshotGroupsByID = () => {
        updateSnapshotGroupsByID(group['@id'], { "snapshots": []})
            .then(( {data} ) => {
                onClear(group.creator['@id'], group['@id']);
            })
            .catch((error) => {
                alert('Something whent wrong');
            });
    }

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    Remove all  snapshots from group
                </Tooltip>
            }
        >
            <button
                type="button"
                className="btn btn-clean btn-md btn-icon"
                onClick={() => _updateSnapshotGroupsByID()}
            >
                <i className="la la-folder-open"/>
            </button>
        </OverlayTrigger>
    );
}

export default withRouter(injectIntl(
    connect(
        null,
        snapshots.actions
    )(ClearGroup)
));