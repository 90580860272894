import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import { Pagination as PaginationStrap } from "reactstrap";
import PaginationNext from "./PaginationNext";
import PaginationPrev from "./PaginationPrev";

export default function Pagination(props) {
    const [prevPage, setPrevPage] = useState(0);
    const [nextPage, setNextPage] = useState(0);

    const { page, perPage, total, path } = props;

    useEffect(() => {
        const totalPages = Math.ceil( total/perPage );

        if( page >= totalPages) {
            setNextPage(0);
        } else {
            setNextPage(page + 1);
        }

        if( page <= 1) {
            setPrevPage(0);
        } else {
            setPrevPage(page - 1);
        }
    });



    return (
        <PaginationStrap>
            <PaginationPrev page={prevPage} path={path}/>
            <PaginationNext page={nextPage} path={path}/>
        </PaginationStrap>
    );
}
