import decodeJwt from "jwt-decode";
import LocalStorageService from "../store/services/LocalStorageService";

const localStorageService = LocalStorageService.getService();


export function getUserIdFromToken(token) {
    if(typeof token === "undefined") {
        token = localStorageService.getAccessToken('access_token');
    }

    return decodeJwt(token).id;
}