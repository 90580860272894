import React, {useEffect} from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {injectIntl} from "react-intl";

import * as users from "../../store/ducks/users.duck";

import UsersTable from "../../widgets/UsersTable";
import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

import Can from "../../components/Can/Can";
import InviteUser from "../../components/InviteUser/InviteUser";


export function Users(props) {

    useEffect(() => {
        const { match, page } = props;

        if(typeof match.params.page !== "undefined" && page != match.params.page) {
            props.changePage(parseInt(match.params.page));
        }
    });

    const {
        usersAuth
    } = props

    return (
        <>
            <Row>
                <Col sm={{ size: 4, offset: 8 }} className="clearfix" style={{
                    paddingBottom: 25
                }}>
                    <InviteUser />
                </Col>
            </Row>
            <Can
                roles={usersAuth.roles}
                perform="users:add"
                yes={() => (
                    <Row>
                        <Col sm={{ size: 6, offset: 6 }} className="clearfix" style={{
                            paddingBottom: 25
                        }}>
                            <Link to="/users/add"  className="float-right btn btn-primary"  style={
                                {
                                    "width": 140
                                }
                            }>Add user</Link>
                        </Col>
                    </Row>
                )}
            />

            <UsersTable />
        </>
    );
}

const mapStateToProps = store => ({
    usersAuth: store.auth.users
});

export default withRouter(injectIntl(
    connect(
        mapStateToProps,
        users.actions
    )(Users)
));