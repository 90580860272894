import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getSnapshotsCollection } from "../../crud/snapshots.crud";

export const actionTypes = {
  CollectionRequested: "[Load snapshots collection] Action",
  SnapshotsRequest: "[Load snapshot by id] Action",
  Delete: "[Delete] Action",
  ChangePage: "[Change page] Action",
  ChangeGroup: "[Change page] Action",
  Refresh: "[Refresh snapshots ] Action"
};

const initialSnapshotsState = {
  snapshots: [],
  total: 0,
  perPage: 30,
  page: 1,
  group: ""
};
export const reducer = persistReducer(
    { storage, key: "snapshots"},
    (state = initialSnapshotsState, action) => {
      switch (action.type) {
        case actionTypes.CollectionRequested: {
          const { snapshots } = action.payload;

          return { ...state, snapshots:snapshots['hydra:member'], total: snapshots['hydra:totalItems'] };
        }

        case actionTypes.Delete: {
          return state;
        }

        case actionTypes.ChangePage: {
          const { page } = action.payload
          return { ...state, page };
        }

        case actionTypes.ChangeGroup: {
          const { group } = action.payload
          return { ...state, group };
        }

        default:
          return state;
      }
    }
);

export const actions = {
  requestCollection: snapshots => ({
    type: actionTypes.CollectionRequested,
    payload: { snapshots }
  }),
  delete: () => ({
    type: actionTypes.Delete
  }),
  changePage: page => ({
    type: actionTypes.ChangePage,
    payload: { page }
  }),
  changeGroup: group => ({
    type: actionTypes.ChangeGroup,
    payload: { group }
  })
};

export function* saga() {

}