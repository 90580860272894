import axios from "axios";

import { SNAPSHOTS_URL  } from "../config/app.api.config";

export function getSnapshotsCollection(page) {
  return axios.get(SNAPSHOTS_URL, {
    params: {
      page: page
    }
  });
}

export function getSnapshotsByID(id) {
  return axios.get(id);
}

export function deleteSnapshotsById(id) {
  return axios.delete(id);
}

export function editSnapshotByID(id, params) {
  return axios.put(id, params );
}