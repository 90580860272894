import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../crud/users.crud";
import * as routerHelpers from "../../router/RouterHelpers";
import LocalStorageService from "../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UsersRequested: "[Request User] Action",
  UsersLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  users: undefined,
  authToken: localStorageService.getAccessToken('access_token')
};

export const reducer = persistReducer(
    { storage, key: "auth", whitelist: ["users", "authToken"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Login: {
          const { authToken } = action.payload;

          localStorageService.setToken( authToken, 'access_token');

          return { authToken, users: undefined };
        }

        case actionTypes.Register: {
          const { authToken } = action.payload;

          return { authToken, users: undefined };
        }

        case actionTypes.Logout: {
          localStorageService.clearToken();
          routerHelpers.forgotLastLocation();
          return { authToken: undefined, users: undefined };
        }

        case actionTypes.UsersLoaded: {
          const { users } = action.payload;
          return { ...state, users };
        }

        default:
          return state;
      }
    }
);

export const actions = {
  login: authToken => ({
    type: actionTypes.Login,
    payload: { authToken }
  }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({
    type: actionTypes.Logout,
    payload: { initialAuthState }
  }),
  requestUsers: users => ({
    type: actionTypes.UsersRequested,
    payload: { users }
  }),
  fulfillUsers: users => ({
    type: actionTypes.UsersLoaded,
    payload: { users }
  })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUsers());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUsers());
  });

  yield takeLatest(actionTypes.UsersRequested, function* usersRequested() {
    const { data } = yield getUserByToken();

    yield put(actions.fulfillUsers(data));
  });
}
