import React, {useState} from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { addRegistration } from "../../crud/registration.crud";
import decodeJwt from "jwt-decode";

function Invite(props) {
    const [successStatus, setSuccessStatus] = useState(false);

    const { intl } = props;

    return (
        <div className="kt-login__body">
            <div className="kt-login__form">
                <div className="kt-login__title">
                    <h3>
                        <FormattedMessage id="AUTH.REGISTER.TITLE" />
                    </h3>
                </div>

                <Formik
                    initialValues={{
                        email: ""
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.email) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
                        setTimeout(() => {
                            setTimeout(() => {
                                addRegistration(values.email, false)
                                    .then( ( { data } ) => {
                                        setSubmitting(false);
                                        setSuccessStatus({
                                            email: data.email
                                        });
                                        resetForm({values: ""})
                                    })
                                    .catch((error) => {
                                        alert(12);
                                        setSubmitting(false);
                                        setStatus(
                                            error.response
                                        );
                                    });
                            }, 1000);
                        }, 1000);
                    }}
                >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}

                            {successStatus && (
                                <div className="alert alert-primary">
                                    <div className="alert-text ">Invite was sent to <strong>{successStatus.email}</strong>. Please check your mailbox for continue.</div>
                                </div>
                            )}

                            <div className="form-group mb-0">
                                <TextField
                                    label="Email"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(touched.email && errors.email)}
                                />
                            </div>

                            <div className="kt-login__actions">

                                <Link to="/auth">
                                    <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
                                        Back
                                    </button>
                                </Link>

                                <button
                                    disabled={isSubmitting}
                                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default injectIntl(Invite);
